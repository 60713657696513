const Service = {}
const { UTCService } = require('module-time')
const moment = require('moment')
import asesorService from '../services/asesor'


Service.setupMensajesWA = async function(mensajes) { 
	const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
	if (!mensajes) {
		return '¡No hay mensajes!.'
	} else {
		const jsonMensajes = mensajes.map((mensaje) => {
			let contador = 0
			mensaje.asesor_id = mensaje.asesor_id ? mensaje.asesor_id : 0
			mensaje.origen =
				mensaje.tipo == 'FIN_SESION'
					? '5'
					: mensaje.tipo == 'FIN_ASESOR'
						? '5'
						: mensaje.tipo == 'REGRESO_COLA'
							? '5'
							: mensaje.tipo == 'AUTOMATIZACION'
								? '4'
								: mensaje.tipo == 'FIN_ASESOR_PROGRAMADO'
									? '5'
									: mensaje.tipo == 'UBICACION'
										? '1'
										: mensaje.tipo == 'INICIO_ASESOR'
											? '4'
											: mensaje.tipo == 'FIN_ASESOR_ENCUESTA'
												? '5'
												: mensaje.tipo.split('_')[0] == 'BOT' ? '1' : mensaje.tipo.split('_')[0] == 'USER' ? '2' : '3'
			mensaje.mensaje = {
				tipo:
					mensaje.tipo == 'FIN_ASESOR'
						? '2'
						: mensaje.tipo == 'FIN_SESION'
							? '3'
							: mensaje.tipo == 'FIN_ASESOR_ENCUESTA'
								? '2'
								: mensaje.tipo == 'AUTOMATIZACION'
									? '1'
									: mensaje.tipo == 'FIN_ASESOR_PROGRAMADO'
										? '2'
										: mensaje.tipo == 'REGRESO_COLA'
											? '1'
											: mensaje.tipo.split('_')[2] == 'UNKNOWN'
												? '9'
												: mensaje.tipo == 'USER_MSJ_STICKER'
													? '2'
													: mensaje.tipo == 'ASESOR_IMG'
														? mensaje.caption != null ? '7' : '2'
														: mensaje.tipo.split('_').length > 0
															? mensaje.tipo.split('_')[2] == 'IMG'
																? mensaje.caption != null ? '7' : '2'
																: mensaje.tipo.split('_')[2] == 'DOCUMENT'
																	? '3'
																	: mensaje.tipo.split('_')[2] == 'VIDEO'
																		? mensaje.caption != null ? '8' : '4'
																		: mensaje.tipo.split('_')[2] == 'AUDIO' ? '5' : mensaje.tipo.split('_')[2] == 'LOCATION' ? '6' : '1'
															: mensaje.tipo.split('_')[2] == 'PDF' ? '3' : '1',
				msj: mensaje.estado
					.split('')
					.map((letra) => {
						if (letra == '*') {
							contador++
							return contador % 2 == 0 ? '</b>' : '<b>'
						}
						if (letra == ' _') {
							contador++
							return '<i>'
						}
						if (letra == '_ ') {
							contador++
							return '</i>'
						}
						return letra
					})
					.join('')
					.replace(/\n/g, '<br>'),
				status_send: mensaje.status_send,
				status_delivered: mensaje.status_delivered,
				fecha: UTCService.getLocalTimeWithFormat(mensaje.createdAt.toString()),
				caption: mensaje.caption != null ? mensaje.caption : '',
			}
			mensaje.updatedAt = (new Date()).toDateString()
			if(mensaje.mensaje.tipo == 2 || mensaje.mensaje.tipo == 7){
				mensaje.mensaje.base64 = base64regex.test(mensaje.estado)
			}
			delete mensaje.tipo
			delete mensaje.estado
			delete mensaje.status_send
			delete mensaje.status_delivered
			delete mensaje.createdAt
			delete mensaje.caption
			return mensaje
		})
		return jsonMensajes
	}
}

Service.setupMensajesGoogle = async function(mensajes) { 

	const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
	if (!mensajes) {
		return '¡No hay mensajes!.'
	} else {
		const jsonMensajes = mensajes.map(  (mensaje) => {
			let contador = 0
			mensaje.asesor_id = mensaje.asesor_id ==null ? mensaje.asesor_id : 0
			mensaje.origen =
			
				mensaje.tipo == 1
					? '2'
					: mensaje.tipo == 4
						? '5'
						: mensaje.tipo == 5
							? '5'
							: mensaje.tipo == 6
								? '4'
								: mensaje.tipo == 9
									? '5'
									: mensaje.tipo == 7
										? '1'
										: mensaje.tipo == 8
											? '4'
											: mensaje.tipo == 9
												? '5'
												:  mensaje.tipo == 3 ? '5' : mensaje.tipo == 2 ? '2' : '3'
			mensaje.mensaje = {
				tipo:   tipo(mensaje.tipo) ,
				msj: mensaje.estado
					.split('')
					.map((letra) => {
						if (letra == '*') {
							contador++
							return contador % 2 == 0 ? '</b>' : '<b>'
						}
						if (letra == ' _') {
							contador++
							return '<i>'
						}
						if (letra == '_ ') {
							contador++
							return '</i>'
						}
						return letra
					})
					.join('')
					.replace(/\n/g, '<br>'),
				status_send: mensaje.status_send ?  2 : -1,
				status_delivered: mensaje.status_delivered?  4 : -1,
				fecha: UTCService.getLocalTimeWithFormat(mensaje.createdAt.toString()),
				caption:   '',
			}
			mensaje.updatedAt = (new Date()).toDateString()
			if(mensaje.mensaje.tipo == 2 || mensaje.mensaje.tipo == 7){
				mensaje.mensaje.base64 = base64regex.test(mensaje.estado)
			}
			delete mensaje.tipo
			delete mensaje.estado
			delete mensaje.status_send
			delete mensaje.status_delivered
			delete mensaje.createdAt
			delete mensaje.caption
			return mensaje
		})
		return jsonMensajes
	}
}

Service.setupMensajesWANi = async function(mensajes) {
	if (!mensajes) {
		return '¡No hay mensajes!.'
	} else {
		const jsonMensajes = mensajes.map((mensaje) => {
			let contador = 0
			mensaje.asesor_id = mensaje.asesor_id ? mensaje.asesor_id : 0
			mensaje.origen =
				mensaje.tipo == 'UBICACION'
					? '1'
					: mensaje.tipo == 'INICIO_ASESOR'
						? '4'
						: mensaje.tipo == 'FIN_ASESOR' ? '5' : mensaje.tipo.split('_')[0] == 'BOT' ? '1' : mensaje.tipo.split('_')[0] == 'USER' ? '2' : '3'
			mensaje.mensaje = {
				tipo:
				mensaje.tipo == 'FIN_ASESOR'
					? '2'
					: mensaje.tipo.split('_')[2] == 'UNKNOWN'
						? '9'
						: mensaje.tipo.split('_').length > 0
							? mensaje.tipo.split('_')[2] == 'IMG'
								? mensaje.caption != null ? '7' : '2'
								: mensaje.tipo.split('_')[2] == 'DOCUMENT'
									? '3'
									: mensaje.tipo.split('_')[2] == 'VIDEO'
										? mensaje.caption != null ? '8' : '4'
										: mensaje.tipo.split('_')[2] == 'AUDIO' ? '5' : mensaje.tipo.split('_')[2] == 'LOCATION' ? '6' : '1'
							: mensaje.tipo.split('_')[2] == 'PDF' ? '3' : '1',
				msj: mensaje.estado
					.split('')
					.map((letra) => {
						if (letra == '*') {
							contador++
							return contador % 2 == 0 ? '</b>' : '<b>'
						}
						if (letra == '_') {
							contador++
							return contador % 2 == 0 ? '</i>' : '<i>'
						}
						return letra
					})
					.join('')
					.replace(/\n/g, '<br>'),
					// status_send: (mensaje.status_send ? mensaje.status_send.toUpperCase() : mensaje.status_send) == 'DELIVERED' || (mensaje.status_send ? mensaje.status_send.toUpperCase() : mensaje.status_send) == 'READ' ? 2 : -1,
				status_send: mensaje.status_send ? (mensaje.status_send.toUpperCase() == 'DELIVERED' || mensaje.status_send.toUpperCase() == 'READ' || mensaje.status_send.toUpperCase() == 'SENT' ? 2 : -1) : -1,
				status_delivered: (mensaje.status_send ? mensaje.status_send.toUpperCase() : mensaje.status_send) == 'READ' ? 5 : (mensaje.status_send ? mensaje.status_send.toUpperCase() : mensaje.status_send) == 'DELIVERED' ? 4 : -1,
				fecha: UTCService.getLocalTimeWithFormat(mensaje.createdAt.toString()),
				caption: mensaje.caption != null ? mensaje.caption : ''
			}
			mensaje.updatedAt = (new Date()).toDateString()
			delete mensaje.tipo
			delete mensaje.estado
			delete mensaje.status_send
			delete mensaje.status_delivered
			delete mensaje.createdAt
			delete mensaje.caption
			return mensaje
		})
		return jsonMensajes
	}
}

Service.setupMensajesFB = async function(mensajes) {
	const response = await asesorService.formatearMensajesFB(mensajes)
	return response.data.map(e => {
		return {
			...e,
			updatedAt: (new Date()).toDateString()
		}
	})
}

Service.setupChatWA = async function(resultado) {
	let users
	let network
	if (resultado.users_wmx) {
		users = resultado.users_wmx
		network = 'WA'
	} else if (resultado.users_wni) {
		users = resultado.users_wni
		network = 'WAni'
	} else if (resultado.users_whe) {
		users = resultado.users_whe
		network = 'WAhemo'
	} else if (resultado.users_wmedicos) {
		users = resultado.users_wmedicos
		network = 'WAmedicos'
	} else if (resultado.users_petct) {
		users = resultado.users_petct
		network = 'WApetct'
	} else if (resultado.users_fb) {
		users = resultado.users_fb
		network = 'FB'
	} else if (resultado.users_wsa) {
		users = resultado.users_wsa
		network = 'WAsa'
	} else if (resultado.users_wgml) {
		users = resultado.users_wgml
		network = 'WAgml'
	}
	else if (resultado.soporte_users) {
		users = resultado.soporte_users
		network = 'WAmesa'
	}
	else if (resultado.users_google) {
		users = resultado.users_google
		network = 'GOOGLE'
	}
	if (!users || users.length === 0) {
		return '¡No hay pacientes!.'
	} else {
		const jsonPacientes = users.map((paciente) => {
			paciente.espera = !!paciente.espera
			paciente.mensajeId = paciente.id
			paciente.session = moment(moment().format('YYYY-MM-DD HH:mm:ss')).diff(paciente.ultima_conexion, 'hours') >= 24 ? false : true
			paciente.network = network
			// paciente.session =
			if (paciente.origen_tipo) {
				paciente.origen =
					paciente.origen_tipo.split('_')[0] == 'BOT'
						? '1'
						: paciente.origen_tipo.split('_')[0] == 'USER'
							? '2'
							: paciente.origen_tipo.split('_')[0] == 'INICIO'
								? '4'
								: paciente.origen_tipo.split('_')[0] == 'ASESOR' ? '3' : paciente.origen_tipo.split('_')[0] == 'TRASPASO' ? '5' : '3'
				paciente.tipoMsj =
					paciente.origen_tipo.split('_')[1] == 'UNKNOWN'
						? '9'
						: paciente.origen_tipo.split('_').length > 0
							? paciente.origen_tipo.split('_')[1] == 'IMAGE'
								? '2'
								: paciente.origen_tipo.split('_')[2] == 'DOCUMENT'
									? '3'
									: paciente.origen_tipo.split('_')[2] == 'VIDEO'
										? '4'
										: paciente.origen_tipo.split('_')[2] == 'AUDIO' ? '5' : paciente.origen_tipo.split('_')[2] == 'LOCATION' ? '6' : '1'
							: '1'
			} else {
				paciente.origen = ''
				paciente.tipoMsj = ''
			}
			delete paciente.ultima_conexion
			return paciente
		})

		return jsonPacientes
	}
}

Service.setupCopys = async function(data){
	return data.map((e) => {
		return{
			id: e.id,
			caption: e.caption,
			msj: e.msj,
			nombre: e.nombre,
			tipo: e.tipo,
			tipo_msj: e.tipo_msj,
			...e.servicio_copy[0]
		}
	})
}

const tipo = function tipo(val) {
    switch (parseInt(val)) {
        case 4:
            return '2';
        case 3:
            return '3';
        case 9:
            return '2';
        case 6:
            return '1';
        case 13:
            return '2';
        case 5:
            return '1';
        case 12:
            return '9';
        case 14:
            return '2';
        case 2:
            return '7';
        case 15:
            return '3';
        case 16:
            return '8';
        case 17:
            return '5';
        case 7:
            return '6';
        case 18:
            return '3';
        default:
            return '1';
    }
} 
export default Service
