import { gql } from 'graphql-tag'
const Querys = {}

Querys.getMensajes = gql`
	subscription mensajesWA($userid: String) {
		historials_wmx(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			status_delivered: deliveredStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getChatsWA = gql`
subscription chatsWA($asesorId: smallint!) {
	users_wmx(where: {asesor_id: {_eq: $asesorId}, asesor: {_eq: 1}, espera: {_nin: [2]}}, order_by: {fecha_asesor: desc, fijado: desc}) {
	  id
	  senderid
	  nombre
	  horaUltimoMsj: updatedAt
	  espera
	  ultimoMsj: ultimo_mensaje
	  origen_tipo
	  etiquetas
	  ultima_conexion
	  fijado
	}
  }
  
`

Querys.getMensajesNi = gql`
	subscription mensajesWANi($userid: String) {
		historials_wni(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getChatsWANi = gql`
	subscription chatsWANi($asesorId: smallint!) {
		users_wni(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
			id
			senderid
			nombre
			horaUltimoMsj: updatedAt
			espera
			ultimoMsj: ultimo_mensaje
			origen_tipo
			etiquetas
			ultima_conexion
		}
	}
`

Querys.getMensajesHe = gql`
	subscription mensajesWAHe($userid: String) {
		historials_whe(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			status_delivered: deliveredStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getChatsWAHe = gql`
	subscription chatsWAHe($asesorId: smallint!) {
		users_whe(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
			id
			senderid
			nombre
			horaUltimoMsj: updatedAt
			espera
			ultimoMsj: ultimo_mensaje
			origen_tipo
			etiquetas
			ultima_conexion
		}
	}
`

Querys.getMensajesFB = gql`
	subscription mensajesFB($userid: String) {
		historials_fb(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			createdAt
			asesor_id
			enviado
		}
	}
`
 
Querys.getChatsFB = gql`
	subscription chatsFB($asesorId: smallint!) {
		users_fb(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
			id
			senderid
			nombre
			horaUltimoMsj: updatedAt
			espera
			ultimoMsj: ultimo_mensaje
			origen_tipo
			etiquetas
			ultima_conexion
		}
	}
`

Querys.getMensajesWASa = gql`
	subscription mensajesWASa($userid: String) {
		historials_wsa(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getChatsWASa = gql`
	subscription chatsWASa($asesorId: smallint) {
		users_wsa(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
			id
			senderid
			nombre
			horaUltimoMsj: updatedAt
			espera
			ultimoMsj: ultimo_mensaje
			origen_tipo
			etiquetas
			ultima_conexion
		}
	}
`

Querys.getChatsWAMedicos = gql`
subscription chatsWAMedicos($asesorId: smallint!) {
	users_wmedicos(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
		id
		senderid
		nombre
		horaUltimoMsj: updatedAt
		espera
		ultimoMsj: ultimo_mensaje
		origen_tipo
		etiquetas
		ultima_conexion
	}
}
`

Querys.getMensajesMedicos = gql`
	subscription mensajesWAMedicos($userid: String) {
		historials_wmedicos(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			status_delivered: deliveredStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`
Querys.getChatsWApet = gql`
subscription chatsWApetct($asesorId: smallint!) {
	users_petct(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
		id
		senderid
		nombre
		horaUltimoMsj: updatedAt
		espera
		ultimoMsj: ultimo_mensaje
		origen_tipo
		etiquetas
		ultima_conexion
	}
}
`

Querys.getMensajesPet = gql`
	subscription mensajesWApetct($userid: String) {
		historials__petct(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			status_delivered: deliveredStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getCopys = gql`
	subscription getCopys {
		informacion_asesor(where: { status: {_eq: 1} }) {
				id
				nombre
				tipo_msj
				msj
				caption
				tipo
			servicio_copy{
				servicio_id: id
				servicio_nombre: nombre
			}
		}
	}
`

Querys.getHorarioMeta = gql`
	subscription getHorarioMeta($id: Int) {
		asesors(where: {id: {_eq:$id}}){
			horario
			meta_hora
		}
	}
`

Querys.getPacientesAtendidos = gql`
	subscription getPacientesAtendidos($id: Int, $fecha_ini: timestamp, $fecha_fin: timestamp) {
	 	pacientesAtendidosByAsesor(args: {asesor_id: $id, fecha_fin: $fecha_fin, fecha_inicio: $fecha_ini}) {
			total
		} 
	}
`

Querys.getBreaksEspeciales = gql`
	subscription getBreaksEspeciales($id: Int, $fechaI: timestamptz, $fechaF: timestamptz) {
		bitacoraasesores(where: { asesor_id: { _eq: $id }, createdAt: { _gte: $fechaI, _lte: $fechaF }, motivo_id: {_in: [ 2,
			3,
			4,
			8,
			10,
			11,
			12,
			13,
			14,												   
			15,
			16,
			17,											   
			19,	
			20,
			21,
			22 ]} }){
			status
			desde
			hasta
			motivo_id
		}
	}
`

Querys.getAsesoresActivos = gql`
subscription getAsesoresActivos($id: Int, $fechaI: timestamptz, $fechaF: timestamptz) {
	asesors(where: {estatus: {_eq: 1}, id: {_neq: $id}}, order_by: {nombre: asc}) {
	  id
	  nombre
	  rol_id
	  estatus
	  login
	  grupo_id
	  bitacoraasesores(where: {createdAt: {_gte: $fechaI, _lte: $fechaF}, status: {_eq: 1}, tipo: {_eq: "BREAK"}}) {
		status
	  }
	}
  }
`

Querys.getMedicosActivos = gql`
subscription getMedicosActivos($id: Int, $fechaI: timestamptz, $fechaF: timestamptz) {
	asesors(where: {estatus: {_eq: 1}, id: {_neq: $id}, grupo_id: {_eq: "6"}}, order_by: {nombre: asc}) {
	  id
	  nombre
	  rol_id
	  estatus
	  login
	  bitacoraasesores(where: {createdAt: {_gte: $fechaI, _lte: $fechaF}, status: {_eq: 1}, tipo: {_eq: "BREAK"}}) {
		status
	  }
	}
  }
`

Querys.getMesaActivos = gql`
subscription getMesaActivos($id: Int, $fechaI: timestamptz, $fechaF: timestamptz) {
	asesors(where: {estatus: {_eq: 1}, id: {_neq: $id}, grupo_id: {_eq: "10"}}, order_by: {nombre: asc}) {
	  id
	  nombre
	  rol_id
	  estatus
	  login
	  bitacoraasesores(where: {createdAt: {_gte: $fechaI, _lte: $fechaF}, status: {_eq: 1}, tipo: {_eq: "BREAK"}}) {
		status
	  }
	}
  }
`

Querys.getPetCTActivos = gql`
subscription getMedicosActivos($id: Int, $fechaI: timestamptz, $fechaF: timestamptz) {
	asesors(where: {estatus: {_eq: 1}, id: {_neq: $id}, grupo_id: {_in: [8, 9]}}, order_by: {nombre: asc}) {
	  id
	  nombre
	  rol_id
	  estatus
	  login
	  bitacoraasesores(where: {createdAt: {_gte: $fechaI, _lte: $fechaF}, status: {_eq: 1}, tipo: {_eq: "BREAK"}}) {
		status
	  }
	}
  }
`
Querys.getChatsGoogle = gql`
subscription chatsGo($asesorId: smallint!) {
	users_google(where: {asesor_id: {_eq: $asesorId}, asesor: {_eq: 1}, espera: {_nin: [2]}}, order_by: {updated_at: desc}) {
	  id
	  senderid :id_partoo
	  nombre
	  horaUltimoMsj: updated_at
	  espera
	  ultimoMsj: ultimo_mensaje
	  origen_tipo
	  etiquetas
	  ultima_conexion : updated_at
	  clinica :  google_suc{
		clinica
	  }
	}
  }
  
`

Querys.getMensajesGoogle = gql`
subscription mensajesGoogle($userid: bigint) {
	historial_google(where: { conversacion_id: { _eq: $userid } }, order_by: { created_at: asc }) {
		id
		senderid: conversacion_id
		tipo :tipo_contenido_id
		estado :contenido
		status_send: mensaje_exitoso
		status_delivered: mensaje_exitoso
		createdAt :created_at
		caption :contenido
		asesor_id
	}
}
`

Querys.getMensajesWAGml = gql`
	subscription mensajesWAGml($userid: String) {
		historials_wgml(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			status_delivered: deliveredStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getChatsWAGml = gql`
	subscription chatsWAGml($asesorId: smallint) {
		users_wgml(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
			id
			senderid
			nombre
			horaUltimoMsj: updatedAt
			espera
			ultimoMsj: ultimo_mensaje
			origen_tipo
			etiquetas
			ultima_conexion
		}
	}
`

Querys.getMensajesWASoporte = gql`
	subscription getMensajesWASoporte($userid: String) {
		soporte_historials(where: { userid: { _eq: $userid }, tipo: { _nin: ["EDO", "WA_STATUS"] } }, order_by: { createdAt: asc }) {
			id
			senderid: userid
			tipo
			estado
			status_send: sentStatusCode
			status_delivered: deliveredStatusCode
			createdAt
			caption
			asesor_id
		}
	}
`

Querys.getChatsWASoporte = gql`
	subscription getChatsWASoporte($asesorId: smallint) {
		soporte_users(where: { asesor_id: { _eq: $asesorId }, asesor: { _eq: 1 }, espera: { _nin: [2] } }, order_by: { updatedAt: desc }) {
			id
			senderid
			nombre
			horaUltimoMsj: updatedAt
			espera
			ultimoMsj: ultimo_mensaje
			origen_tipo
			etiquetas
			ultima_conexion
		}
	}
`

Querys.getMesaActivosMesa = gql`
subscription getMesaActivos($id: Int, $fechaI: timestamptz, $fechaF: timestamptz) {
	asesors(where: {estatus: {_eq: 1}, id: {_neq: $id}, grupo_id: {_eq: "10"}}, order_by: {nombre: asc}) {
	  id
	  nombre
	  rol_id
	  estatus
	  login
	  bitacoraasesores(where: {createdAt: {_gte: $fechaI, _lte: $fechaF}, status: {_eq: 1}, tipo: {_eq: "BREAK"}}) {
		status
	  }
	}
  }
`

//filas
Querys.poolColas = gql`
subscription poolColas {
  poolCoolas {
    agendar_cita_asesor
    agendar_cita_max_tiempo
    agendar_cita_noasesor
    cotizaciones_asesor
    cotizaciones_cita_max_tiempo
    cotizaciones_noasesor
    devoluciones_asesor
    devoluciones_max_tiempo
    devoluciones_noasesor
    facturacion_asesor
    facturacion_max_tiempo
    facturacion_noasesor
    lentes_asesor
    lentes_max_tiempo
    lentes_noasesor
    noespesificado_asesor
    noespesificado_asesor_max_tiempo
    noespesificado_noasesor
    otro_asesor
    otro_cita_max_tiempo
    otro_noasesor
    resultados_asesor
    resultados_max_tiempo
    resultados_noasesor
    solicitudes_asesor
    solicitudes_con_asesor
    solicitudes_max_tiempo
    solicitudes_sin_asesor
    ubicaciones_asesor
    ubicaciones_max_tiempo
    ubicaciones_noasesor
  }
}
`
Querys.getPacientesAtendidos = gql`
	subscription getPacientesAtendidos($id: Int, $fecha_ini: timestamp, $fecha_fin: timestamp) {
	 	pacientesAtendidosByAsesor(args: {asesor_id: $id, fecha_fin: $fecha_fin, fecha_inicio: $fecha_ini}) {
			total
		} 
	}
`


Querys.getAsesoresTiempoReal = gql`
subscription asesoresTiempoReal($fecha_inicio: timestamptz, $fecha_fin: timestamptz) {
  asesoresTiempoReal(args: { fecha_inicio: $fecha_inicio, fecha_fin: $fecha_fin }, order_by: { sesion: desc }) {
    id
    agendar_cita_inbox
    agendar_cita_pendientes
	break
    cola
    cotizaciones_inbox
    cotizaciones_pendientes
    devoluciones_inbox
    devoluciones_pendientes
    estatus_asesor
    facebook
    facturacion_inbox
    facturacion_pendientes
    fin_sesion
    grupo_id
    horario
    lastconnection
    lentes_inbox
    lentes_pendientes
    max_tiempo
    nombre
    num_pacientes
    otro_inbox
    otro_pendientes
	desconocido_pendientes
    desconocido_inbox
    resultados_inbox
    resultados_pendientes
    rol_id
    sesion
    solicitudes_asesor
    ubicaciones_inbox
    ubicaciones_pendientes
	 felizometro
  }
}
`





export default Querys
