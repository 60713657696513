import api from './api'
import apiHSM from './apiHSM'
import apiFB from './apiFB'
const moment = require('moment')
require('moment-precise-range-plugin');
const { UTCService } = require('module-time')
// import authHeader from './auth-header';

let service = {}
let network = 'WA'
let sesionUsuario = JSON.parse(sessionStorage.getItem('sesion')) || false

if(sesionUsuario.grupo_id==6){
    network= 'WAmedicos'
}
if (sesionUsuario.grupo_id == 10) {
    network = 'WAmesa'
}

let config = {
    headers: {
    "Content-Type": "multipart/form-data; boundary=${form._boundary}'"
    }
  }

service.actualizarNetwork = function(networks){
    network = networks
}
service.getNetwork = function(){

    return network
}
service.pedirPaciente = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    if(network=='WA'){
        return api.post('/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='WAni'){
        return api.post('/nicaragua/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='WAhemo'){
        return api.post('/hemodialisis/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='WApetct'){
        return api.post('/petct/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='WAmedicos'){
        return api.post('/medicos/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='FB'){
        return apiFB.get('/asesor/getcola/'+asesor.id).then(res => res.data)
    }else if(network=='WAsa'){
        return api.post('/salvador/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='GOOGLE'){
        return api.post('/google/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else if(network=='WAgml'){
        return api.post('/guatemala/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    } else if (network == 'WAmesa') {
        return api.post('/soporte/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }
    
}

service.formatearMensajesFB = async function(mensajes) {
    return await apiFB.post('/asesor/paciente/inbox/formato', {mensajes: mensajes})
}
 

service.sendMensaje = function(senderId, mensaje) {
    if (mensaje == '' || senderId == '') {
        return
    }

    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender_actual')
    if(senderId!=sender){
        senderId=sender
    }
    if(network=='WA'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/asesor/enviarmensaje', datos).then(res => res.data)
    }else if(network=='WAni'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/nicaragua/asesor/enviarmensaje', datos).then(res => res.data)
    }else if(network=='WAhemo'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/hemodialisis/asesor/enviarmensaje', datos).then(res => res.data)
    }else if(network=='WApetct'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/petct/asesor/enviarmensaje', datos).then(res => res.data)
    }else if(network=='WAmedicos'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/medicos/enviarmensaje', datos).then(res => res.data)
    }else if(network=='FB'){
        let datos = {
            senderid: senderId,
            asesorId: asesor.id,
            mensaje: mensaje
        }
        return apiFB.post('/asesor/send/', datos).then(res => res.data)
    }else if(network=='WAsa'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/salvador/asesor/enviarmensaje', datos).then(res => res.data)
    }else if(network=='GOOGLE'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/google/enviarmensaje', datos).then(res => res.data)
    }else if(network=='WAgml'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/guatemala/asesor/enviarmensaje', datos).then(res => res.data)
    }
    else if (network == 'WAmesa') {
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/soporte/asesor/enviarmensaje', datos).then(res => res.data)
    }
}

service.pacientesEnCola = function() {
    if((network=='WA') && (window.location.pathname == '/chat')){
        return true
        //return api.get('/asesor/pacientesencola').then(res => res.data)
    }else{
        return apiFB.get('/asesor/cola').then(res => res.data)
    }
}
service.pacientesAtendiendoEnCola = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        asesorId: asesor.id
    }
    return api.post('/asesor/pacientesatendiendo', datos).then(res => res.data)
}
service.finalizarPaciente = function(senderId) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        senderId: senderId,
        asesorId: asesor.id
    }
    if(network=='WA'){
        return api.post('/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='WAni'){
        return api.post('/nicaragua/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='WAhemo'){
        return api.post('/hemodialisis/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='WApetct'){
        return api.post('/petct/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='WAmedicos'){
        return api.post('/medicos/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='FB'){
        return apiFB.get('/asesor/finalizar/'+ senderId +'/'+asesor.id).then(res => res.data)
    }else if(network=='WAsa'){
        return api.post('/salvador/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='GOOGLE'){
        return api.post('/google/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='WAgml'){
        return api.post('/guatemala/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if (network == 'WAmesa') {
        return api.post('/soporte/asesor/paciente/finalizar', datos).then(res => res.data)
    }
}
service.finalizarPacienteEncuesta = function(senderId) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        sender_id: senderId,
        asesor_id: asesor.id,
        encuesta_id: 2
    }
    return api.post('/asesor/encuesta', datos).then(res => res.data)
}
service.finalizacionProgramada = function(senderId) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        senderid: senderId,
        asesor_id: asesor.id,
        flujo: 10
    }
    
    if(network=='WA'){
        return api.post('/asesor/pacienteatendido', datos).then(res => res.data)
    }else if(network=='WAhemo'){
        return api.post('/hemodialisis/asesor/pacienteatendido', datos).then(res => res.data)
    }else if(network=='WApetct'){
        return api.post('/petct/asesor/pacienteatendido', datos).then(res => res.data)
    }
}
service.listaDeAsesores = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.post('/asesores', { asesorId: asesor.id }).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}
service.traspasarPaciente = function(senderId, asesor_traspaso, comentario) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        "paciente_id": senderId,
        "asesor_origen": asesor.id,
        "asesor_traspaso": asesor_traspaso,
        "comentario": comentario
    }
    if((network=='FB') && ((window.location.pathname == '/chat')||(window.location.pathname == '/dashboard/monitor'))){
        return apiFB.post('/asesor/traspaso', datos).then(res => res.data)
    }else if(network=='WAni'){
        return api.post('/nicaragua/asesor/transferirpaciente', datos).then(res => res.data)
    }else if(network=='WAhemo'){
        return api.post('/hemodialisis/asesor/transferirpaciente', datos).then(res => res.data)
    }else if(network=='WApetct'){
        return api.post('/petct/asesor/transferirpaciente', datos).then(res => res.data)
    }else if(network=='WAmedicos'){
        return api.post('/medicos/transferirpaciente', datos).then(res => res.data)
    }else if(network=='WAsa'){
        return api.post('/salvador/asesor/transferirpaciente', datos).then(res => res.data)
    }else if(network=='WAgml'){
        return api.post('/guatemala/asesor/transferirpaciente', datos).then(res => res.data)
    } else if (network == 'WAmesa') {
        return api.post('/soporte/asesor/transferirpaciente', datos).then(res => res.data)
    }else{
        return api.post('/asesor/transferirpaciente', datos).then(res => res.data)
    }
}
service.listaMotivos = function() {
    return api.get('/motivos').then(res => res.data)
}
service.respuestas = function(asesor) {
    return api.get('/asesor/respuestas/'+ asesor).then(res => res.data)
}
service.getRespuestas = function() {
    return api.get('/asesor/respuestas').then(res => res.data)
}
service.NuevaRespuesta = function(respuesta) {
    return api.post('/asesor/respuestas', respuesta).then(res => res.data)
}
service.EditarRespuesta = function(respuesta) {
    return api.put('/asesor/respuestas', respuesta).then(res => res.data)
}
service.traspasarPacientesMasivo = function(asesor_traspaso, comentario) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        "asesor_origen": asesor.id,
        "asesor_traspaso": asesor_traspaso,
        "comentario": comentario
    }
    return api.post('/asesor/transferirpacientes', datos).then(res => res.data)
}
service.traspasarPacientesConEtiqueta= function(asesor_traspaso, comentario, etiqueta) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        "asesor_origen": asesor.id,
        "asesor_traspaso": asesor_traspaso,
        "comentario": comentario,
        "etiqueta": etiqueta
    }
    if(network=='WA'){
        return api.post('/asesor/traspasoetiquetas', datos).then(res => res.data)
    }else if(network=='WAhemo'){
        return api.post('/hemodialisis/asesor/traspasoetiquetas', datos).then(res => res.data)
    }else if(network=='WApetct'){
        return api.post('/petct/asesor/traspasoetiquetas', datos).then(res => res.data)
    }else if(network=='WAmedicos'){
        return api.post('/medico/traspasoetiquetas', datos).then(res => res.data)
    }else if(network=='WAsa'){
        return api.post('/salvador/asesor/traspasoetiquetas', datos).then(res => res.data)
    }else if(network=='WAgml'){
        return api.post('/guatemala/asesor/traspasoetiquetas', datos).then(res => res.data)
    }else if (network == 'WAmesa') {
        return api.post('/soporte/asesor/traspasoetiquetas', datos).then(res => res.data)
    }else {
        return api.post('/nicaragua/asesor/traspasoetiquetas', datos).then(res => res.data)
    }
}
service.traspasarPacientesParametros = function(asesor_traspaso) {
    let datos = {
        "asesor_origen": asesor_traspaso,
        "asesor_traspaso": asesor_traspaso,
        "comentario": 1,
        "mandarCola": true
    }
    return api.post('/asesor/transferirpacientes', datos).then(res => res.data)
}
service.traspasarPacientePlataforma = function(senderId) {
    let datos = {
        paciente_id: senderId
    }
    return api.post('/asesor/traspasoplataforma', datos).then(res => res.data)
}
service.pacienteEspera = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender')

    let datos = {
        senderId: sender,
        asesorId: asesor.id
    }
    return api.post('/asesor/pacienteespera', datos).then(res => res.data)
}
service.consultarRSV = function(rsv) {
    let senderid = sessionStorage.getItem('sender_actual')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    if(network=='WA'){
        return api.post('/asesor/estatusresultados', { rsv, senderid, asesor_id }  ).then(res => res.data)
    }else if(network=='GOOGLE'){
        return api.post('/google/estatusresultados', { rsv, senderid, asesor_id }).then(res => res.data)
    }else{
        return apiFB.get('/asesor/resultados/'+asesor.id +'/'+senderid+'/'+rsv).then(res => res.data)
    }
}
service.consultarExamen = function(buscar) {
    if(network=='WA'){
        return api.get('/asesor/buscar/examenvista/'+buscar).then(res => res.data)
    }
}
service.buscarExamen = function(buscar) {
    let senderid = sessionStorage.getItem('sender_actual')
    if(network=='WA'){
        return api.get('/asesor/ver/examenvista/'+buscar+'/'+senderid).then(res => res)
    }
}
service.enviarExamen = function(folio) {
    let senderid = sessionStorage.getItem('sender_actual')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let formulario={
        asesor_id: asesor.id,
        senderid: senderid,
        folio: folio
    }
    if(network=='WA'){
        return api.post('/asesor/enviar/examenvista', formulario).then(res => res.data)
    }
}
service.enviarRSV = function(rsv) {
    let senderid = sessionStorage.getItem('sender_actual') 

    if(network=='WA'){
        return api.post('/asesor/enviarresultados', { rsv, senderid }).then(res => res.data)
    }else if(network=='GOOGLE'){
        return api.post('/google/enviarresultados',  { rsv, senderid }).then(res => res.data)
    }
    else{
        let asesor = JSON.parse(sessionStorage.getItem('sesion'))
        let senderid = sessionStorage.getItem('sender_actual')
        let datos = {
            "senderid":senderid,
            "asesor_id":asesor.id
        }
        return apiFB.post('/asesor/resultados/enviar',datos).then(res => res.data)
    }
}
service.imgLink = function() {
    let senderid = sessionStorage.getItem('sender_actual') 

    if(network=='WA'){
        return api.post('/asesor/imagenesrv', { senderid }).then(res => res.data)
    }else{
        return api.post('/google/imagenesrv', { senderid }).then(res => res.data)
    }
    
}
service.rsvCorreo = function(correo) {
    let senderid = sessionStorage.getItem('sender_actual')

    if(network=='WA'){
        return api.post('/asesor/enviarresultadoscorreo', { senderid, correo }).then(res => res.data)
    }else if(network=='GOOGLE'){ 
        return api.post('/google/enviarresultadoscorreo', { senderid, correo }).then(res => res.data)
    }
}
service.EstudioSuc = function(sucursal_id){
    return api.get('/asesor/sucursal/estudios?sucursal_id='+sucursal_id).then(data=>data.data)
}
service.subEstudios = function(sucursal_id, estudio_id, tipo){
    return api.get('/asesor/sucursal/subestudios?sucursal_id='+sucursal_id+'&estudio_id='+estudio_id+'&tipo='+tipo).then(data=>data.data)
}
service.cotizarPrecios = function(sucursal_id, estudio_id, pr, tipo){
    let senderid = sessionStorage.getItem('sender_actual')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    if(tipo==1){
        return api.get('/asesor/sucursal/subestudio?sucursal_id='+sucursal_id+'&estudio_id='+estudio_id+'&subestudio_id='+pr+'&senderid='+senderid+'&asesor_id='+asesor_id).then(data=>data.data)

    } else{
        return api.get('/asesor/sucursal/subestudio?sucursal_id='+sucursal_id+'&estudio_id='+estudio_id+'&paquete_id='+pr+'&senderid='+senderid+'&asesor_id='+asesor_id).then(data=>data.data)

    }
}
service.flujos = function (flujo){
    let senderid = sessionStorage.getItem('sender_actual')||sessionStorage.getItem('sender')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    return api.post('/asesor/enviaraflujo', { senderid, asesor_id, flujo }).then(res => res.data)
}
service.ubicarClinica = function(sucursal) {
    return api.post('/asesor/sucursales', { sucursal }).then(res => res.data)
}
service.enviarUbicacionClinica = function(clinica) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender_actual')

    let datos = {
        senderid: sender,
        asesorid: asesor.id,
        clinica_id: clinica
    }
    
    return api.post('/'+(network=='WAmedicos'?'medicos':'asesor')+'/enviarsucursal', datos).then(res => res.data)
}
service.cerrarSesion = function (){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    return api.post('/closed', { asesor_id }).then(res => res.data)
}
service.estatus = function(asesor, estatus) {
    let datos = {
        asesor_id: asesor,
        estatus: estatus
    }
    return api.post('/asesor/estatus', datos).then(res => res.data)
}
service.enviarPdf = function(nombreArchivo, url) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender')
    let datos = {
        asesor_id: asesor.id,
        senderid: sender,
        caption: nombreArchivo,
        pdf: url
    }
    return api.post('/pdf/send', datos).then(res => res.data)
}
service.etiquetar = function (sender, obj) {

    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        asesor_id: asesor.id,
        senderid: sender,
        etiquetas: obj
    }
    if(network=='WA'){
        return api.post('/asesor/etiqueta', datos).then(res => res.data)
    } else if(network=='WAni'){
        return api.post('/nicaragua/asesor/etiqueta', datos).then(res => res.data)
    } else if(network=='WAhemo'){
        return api.post('/hemodialisis/asesor/etiqueta', datos).then(res => res.data)
    } else if(network=='WApetct'){
        return api.post('/petct/asesor/etiqueta', datos).then(res => res.data)
    } else if(network=='WAmedicos'){
        return api.post('/medicos/etiqueta', datos).then(res => res.data)
    } else if(network=='WAsa'){
        return api.post('/salvador/asesor/etiqueta', datos).then(res => res.data)
    } else if(network=='FB'){
        return apiFB.post('/asesor/etiqueta', datos).then(res => res.data)
    }else if(network=='WAgml'){
        return api.post('/guatemala/asesor/etiqueta', datos).then(res => res.data)
    }else if (network == 'WAmesa') {
        return api.post('/soporte/asesor/etiqueta', datos).then(res => res.data)
    }
    else if(network=='GOOGLE'){
        return api.post('/asesor/etiqueta', datos).then(res => res.data)
    }
}

service.Atendidos = function(){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.get('/asesor/atendidos?asesor_id='+asesor.id).then(data=>data.data)
}

service.mandarEspera = function(sender){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        asesor_id: asesor.id,
        senderid: sender,
    }
    return api.post('/asesor/espera', datos).then(data=>data.data)
}
service.bloquearPx = function(datos){

    return apiHSM.post('/api/blacklist/add', datos).then(data=>data.data)
}
service.covidInfo = function() {
    return api.get('/covid/informacion').then(res => res.data)
}

service.laLuchaInfo = function() {
    return api.get('/asesor/informacion/OCTUBREROSA').then(res => res.data)
}
service.covidInfoEnviar = function(id) {
    let sender = JSON.parse(sessionStorage.getItem('sender_actual'))
    let datos = {
        id: id,
        asesor_id:JSON.parse(sessionStorage.getItem('sesion')).id,
        senderid: sender,
    }
    return api.post('/asesor/copys/enviarCopy', datos).then(res => res.data)
}

service.notificaciones = function() {
    return api.get('/api/asesor-notificaciones').then(res => res.data)
}
service.bajaNotificacion = function(id) {
    return api.put('/api/asesor-notificaciones/delete/'+id).then(res => res.data)
}
service.vistoNotificacion = function(id) {
    return api.put('/api/asesor-notificaciones/visto/'+id).then(res => res.data)
}
service.usuarios = function() {
    return api.get('/asesors').then(res => res.data)
}
service.editarUsuario = function(datos) {
    return api.put('/asesor',datos).then(res => res.data)
}
service.nuevoUsuario = function(datos) {
    return api.post('/asesor/create',datos).then(res => res.data)
}
service.subirImg = function (formData,id) {
    return api.post('/asesor/subirImagen/'+id, formData ,config).then(res => res.data)
}
service.estatusFelizometro = function(a,b,c,d){
    let datosFelizometro= {
            asesor_id: a,
            estatus: b,
            descripcion: c,
            entradaTurno: d
        }
    return api.post('/asesors/felizometro/',datosFelizometro).then(res => res.data)
}
service.getEstatusFelizometro = function(){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.post('/asesors/felizometro/'+asesor.id).then(res => res.data)
}
service.getFelizometro = function(){
    return api.get('/asesors/felizometro/obtenerFelizometroPorFechaActual').then(res => res.data)
}
service.getActivosPorServicio=function(){
    return api.get('/asesors/copys/obtenerActivosPorServicio').then(res => res.data)
}
service.getTodoPorServicio=function(){
    return api.get('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/obtenerTodoPorServicio').then(res => res.data)
}
service.crearCopyTxt=function(a,b,c,d,e,f){
    let datosCopyTxt={
        tipo: b.trim(),
        nombre: a.trim(),
        tipo_msj: "TEXT",
        msj: c.trim(),
        status: d,
        servicio_id: e,
        asesor_id: f
    }
    return api.post('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/crearCopy',datosCopyTxt).then(res => res.data)
}
service.crearCopyImg = function(formData) {
    return api.post('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/crearCopy', formData, config)
    .then(res => res.data)
    .catch(error => {
        console.error("Error al crear la imagen:", error);
    });
};

service.crearCopyPdf=function(formData){
   return api.post('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/crearCopy/',formData, config).then(res => res.data)
}
service.getServicios=function(){
    return api.get('/asesors/servicios').then(res => res.data)
}
service.editarCopy = function(id, formData) {
    return api.put('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/actualizarCopy/'+id, formData, config).then(res => res.data)
}
service.registrarLog = function(datos) {
    return api.post('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/registrarLog',datos).then(res => res.data)
}
service.desactivarCopy = function(id) {
    return api.put('/'+(network=='WAmedicos'?'medicos':'asesors')+'/copys/desactivarCopy/'+id).then(res => res.data)
}
service.getMensajes=function(){
    return api.get('/WASitio/obtenerMensajes').then(res => res.data)
}
service.getBotones=function(){
    return api.get('/WASitio/obtenerBotones').then(res => res.data)
}
service.crearBoton=function(a,b,c,d){
    let datosBoton={
        frase: a.trim(),
        descripcion: b.trim(),
        estatus: c,
        asesor_id: d
    }
    return api.post('/WASitio/crearBoton',datosBoton).then(res => res.data)
}
service.crearMensaje=function(a,b,c){
    let datosMensaje={
        frase: a.trim(),
        estatus: b,
        asesor_id: c
    }
    return api.post('/WASitio/crearMensaje',datosMensaje).then(res => res.data)
}
service.actualizarBoton=function(a,b,c,d,e){
    let datosBoton={
        frase: b.trim(),
        descripcion: c.trim(),
        estatus: d,
        asesor_id: e
    }
    return api.put('/WASitio/actualizarBoton/'+a,datosBoton).then(res => res.data)
}
service.actualizarBotonOrden=function(id,orden){
    let datosorden={
        orden:orden
    }
    return api.put('/WASitio/actualizarBoton/'+id,datosorden).then(res => res.data)
}
service.desactivarBoton=function(id){
    return api.put('/WASitio/desactivarBoton/'+id).then(res => res.data)
}
service.actualizarMensaje=function(a,b,c,d){
    let datosMensaje={
        frase: b.trim(),
        estatus: c,
        asesor_id: d
    }
    return api.put('/WASitio/actualizarMensaje/'+a,datosMensaje).then(res => res.data)
}
service.desactivarMensaje=function(id){
    return api.put('/WASitio/desactivarMensaje/'+id).then(res => res.data)
}
service.getMotivos=function(){
    return api.get('/asesor/break/motivos').then(res => res.data)
}
service.getEstatusBreak=function(){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.get('/asesor/break/'+asesor.id).then(res => res.data)
}
service.activarBreak=function(a){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let data={
        motivo_id:a,
    }
    return api.post('/asesor/break/'+asesor.id,data).then(res => res.data)
}
service.cerrarBreak=function(asesor_id){
    let asesor = {}
    if(asesor_id) asesor.id = asesor_id
    else asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.put('/asesor/break/'+asesor.id).then(res => res.data)
}

service.getBreaksAsesor=function(){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.get('/asesors/breakPorAsesor/'+asesor.id).then(res => res.data)
}

service.calcularMeta = function({matutino, vespertino, status, metaphora}){
    let horasMatutino = moment(`1900-01-01 ${matutino[1]}`).preciseDiff(`1900-01-01 ${matutino[0]}`, true)
    let horasVespertino = moment(`1900-01-01 ${vespertino[1]}`).preciseDiff(`1900-01-01 ${vespertino[0]}`, true)
    const horasT = !status ? 0 : (horasMatutino.hours + horasVespertino.hours)
    const minutosT = !status ? 0: (horasMatutino.minutes + horasVespertino.minutes)
    const metapminuto = metaphora/60
    const metapminutos = minutosT * metapminuto
    return Math.round((horasT * metaphora) + metapminutos)
}

service.getDia = function(){
    const hoy = moment().locale('es').format('dddd')
    let diaSemana = hoy[0].toUpperCase() + hoy.slice(1)
    diaSemana = diaSemana.normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1').normalize()
    return diaSemana
}

service.getFechasHoy = function(){
    return{
        fechaI: UTCService.getUTCDiaActualInicio(),
        fechaF: UTCService.getUTCDiaActualFinal()
    }
}
service.getAsesoresConver = function(senderId){
    let numero={
        senderid:senderId
    }
    return api.post('/asesor/seguimientos_pacientes/asesorConversacion', numero).then(res =>res.data)
}
service.getMotivosSeguimiento = function(){
    return api.get('/asesor/motivos_seguimientos').then(res =>res.data)
}
service.crearNota = function(data){
    return api.post('/asesor/seguimientos_pacientes/crearNota',data).then(res =>res.data)
}
service.cerrarNota = function(data){
    return api.put('/asesor/seguimientos_pacientes/cerrarNota',data).then(res =>res.data)
}
service.getNota = function(asesorid){
    return api.post('/asesor/seguimientos_pacientes/getNota',asesorid).then(res =>res.data)
}
service.getAsesorEsp = function(){
    return api.get('/asesor/asesores/especifico').then(res =>res.data)
}
service.fijarPaciente = function(senderid){
    return api.put('/asesor/fijarpaciente',{senderid}).then(res =>res.data)
}
service.getComentarios = function(data){
    return api.post('/asesor/comentariosPA',data).then(res =>res.data)
}
service.editarNota = function(data){
    return api.put('/asesor/seguimientos_pacientes/editarNota',data).then(res =>res.data)
}
service.enviarCopyPet = function(data){
    return api.post('/petct/copys/enviarCopy',data).then(res =>res.data)
}
service.enviarCopyMed = function(data){
    return api.post('/medicos/copys/enviarCopy',data).then(res =>res.data)
}
service.getInfoPaciente = function(reservacion,tipo,headers){
    return api.get('https://api.sdigna.org/Pacientes/PacienteEdadYGenero?Reservacion='+reservacion+'&TipoServicio='+tipo,{headers}).then(res =>res.data)
}
service.getTokenSdigna = function(){
    return api.post('https://api.sdigna.org/Auth/GenerarToken?usuario=MarketingSD&password=mXQrIFqO2h').then(res =>res.data)
}
service.getTokenSdignaQ = function(){
    return api.get('https://servicios-whatsapp.sdmkt.org/api/auth/inicioSesion?usuario=sisPrev2022&contrasena=kw34ykroSD2022').then(res =>res.data)
}
service.crearQueja = function(data,headers){
    return api.post('https://servicios-whatsapp.sdmkt.org/api/quejas/RegistroQueja',data,{headers}).then(res =>res.data)
}
service.dashboarAsesorespordia = function(id,fechai,fechaF){
    return api.get('/reporte/dashpacientespordiaasesor?fecha_inicial='+fechai+'&id='+id+'&fecha_final='+fechaF).then(res =>res.data)
}
service.getNotasEsp = function(data){
    return api.post('/asesor/seguimientos_pacientes/getNotaEsp',data).then(res =>res.data)
}
service.getTokenSd = function(){
    return api.post('https://api.sisprevencion.org/api/Seguridad/Mensaje?d1=MarketingSD&d2=mXQrIFqO2h').then(res =>res.data)
}
service.getEstQueja = function(id,headers){
    return api.get('https://api.sisprevencion.org/api/RecuperacionReactiva/EstatusDeFolio?Folio='+id,{headers}).then(res =>res.data)
}
service.getMensajesAnt= function(senderid){
    return api.post('/asesor/pedirMensajes',senderid).then(res =>res.data)
}
service.calcularDeberAtendidos = async function({matutino, vespertino, status, metaphora}){
    if(!status){
        return 0
    }
    moment.tz.setDefault("America/Mazatlan");
    const ahora = moment(`1900-01-01 ${moment().format('HH:mm:ss')}`)
    const matutinoI = moment(`1900-01-01 ${matutino[0]}`)
    const matutinoF = moment(`1900-01-01 ${matutino[1]}`)
    const vespertinoI = moment(`1900-01-01 ${vespertino[0]}`)
    const vespertinoF = moment(`1900-01-01 ${vespertino[1]}`)
    const breaks = await service.getBreaksAsesor()

    let horasMatutino, horasVespertino
    let horasT = moment(`1900-01-01 00:00:00`)
    if(ahora < matutinoI){
        horasMatutino = {hours : 0, minutes: 0, seconds: 0}
        horasVespertino = {hours : 0, minutes: 0, seconds: 0}
    }else if(ahora >= matutinoI && ahora < matutinoF){
        horasMatutino = matutinoI.preciseDiff(ahora, true)
        horasVespertino = {hours : 0, minutes: 0, seconds: 0}
    }else if(ahora >= matutinoF && ahora < vespertinoI){
        horasMatutino = matutinoI.preciseDiff(matutinoF, true)
        horasVespertino = {hours : 0, minutes: 0, seconds: 0}
    }else if(ahora >= vespertinoI && ahora < vespertinoF){
        horasMatutino = matutinoI.preciseDiff(ahora, true)
        horasMatutino = matutinoI.preciseDiff(matutinoF, true)
        horasVespertino = vespertinoI.preciseDiff(ahora, true)
    }else{
        horasMatutino = matutinoI.preciseDiff(matutinoF, true)
        horasVespertino = vespertinoI.preciseDiff(vespertinoF, true)
    }
    horasT.add(horasMatutino.hours, 'hours')
    horasT.add(horasMatutino.minutes, 'minutes')
    horasT.add(horasMatutino.seconds, 'seconds')
    horasT.add(horasVespertino.hours, 'hours')
    horasT.add(horasVespertino.minutes, 'minutes')
    horasT.add(horasVespertino.seconds, 'seconds')
    if(breaks.length > 0){
        breaks.forEach(e => {
            if(e.motivo_id != 18){
                let tiempoBreak
                if(e.status == 0)
                    tiempoBreak = moment(e.desde).preciseDiff(moment(e.hasta), true)
                else
                    tiempoBreak = moment(e.desde).preciseDiff(moment(), true)
                horasT.subtract( tiempoBreak.hours, 'hours')
                horasT.subtract(tiempoBreak.minutes, 'minutes')
                horasT.subtract(tiempoBreak.seconds, 'seconds')
            }
        });
    }
    const horas = horasT.hours()
    const minutos = horasT.minutes()
    const segundos = horasT.seconds()
    const metaphoras = Math.round(metaphora*horas)
    const metapmins = Math.round((metaphora/60)*minutos)
    const metapsegs = Math.round(((metaphora/60)/60)*segundos)
    
    return metaphoras + metapmins + metapsegs
}
service.asignarNumero= function(data){
    return api.post('/soporte/asesor/transferirpaciente',data).then(res =>res.data)
}
//GOOGLE PARTOO
service.enviarCopyGoogle= function(data){
    return api.post('/google/copys/enviarCopy',data).then(res =>res.data)
}
service.getPacientesFinalizados = function () {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.get('/asesors/ConversacionesFinalizadas/' + asesor.id).then(res => res.data)
}
service.regresarPaciente = function (senderid) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'));
    const data = {
        senderid: senderid,
        asesor_id: asesor ? asesor.id : null 
    };
    return api.post('/asesors/RegresarPaciente/', data).then(res => res.data);
}
service.getGrupoAsesor = function () {
    return api.get('/asesors/gruposAsesores').then(res => res.data)
}
export default service