<template>
    <div class="container-fluid">
        <ul class="nav nav-tabs mt-2" style="position:fixed">
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=9 && sesion.grupo_id!=10">
                <a class="nav-link" @click="tab=1" :class="{'active': tab==1}"><span class="h6"> <font-awesome-icon icon="cog"/> Configuración de Asesores</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=9 && sesion.grupo_id!=10">
                <a class="nav-link" @click="tab=9" :class="{'active': tab==9}"><span class="h6"> <font-awesome-icon icon="cog"/> Configuración de Filas</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click="tab=3" :class="{'active': tab==3}" ><span class="h6"><font-awesome-icon icon="user"/> Asesores</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=10">
                <a class="nav-link" @click="tab=2" :class="{'active': tab==2}" ><span class="h6"><font-awesome-icon icon="bell"/> Notificaciones</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=10">
                <a class="nav-link" @click="tab=4" :class="{'active': tab==4}" ><span class="h6"><font-awesome-icon icon="comments"/> Copys</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=10">
                <div v-if="sesion.rol_id == 1"></div>
                <a class="nav-link" @click="tab=5" :class="{'active': tab==5}" ><span class="h6"><font-awesome-icon icon="smile"/> Felizometro</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=10">
                <div v-if="sesion.rol_id == 1"></div>
                <a class="nav-link" @click="tab=6" :class="{'active': tab==6}" ><span class="h6"><i class="icon-search-comment"></i>&nbsp;&nbsp; Respuestas Rapidas</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=10">
                <div v-if="sesion.rol_id == 1"></div>
                <a class="nav-link" @click="tab=7" :class="{'active': tab==7}" ><span class="h6"><i class="icon-whatsapp" style="bold"></i> WA Sitio</span></a>
            </li>
            <li class="nav-item" v-if="sesion.grupo_id!=6 && sesion.grupo_id!=10">
                <div v-if="sesion.rol_id == 1"></div>
                <a class="nav-link" @click="tab=8" :class="{'active': tab==8}" ><span class="h6"><font-awesome-icon icon="newspaper"/> Comentaios Plataforma</span></a> 
            </li>
        </ul>
        <div v-if="tab==1" class="pt-5 my-2">
            <confi-asesor />
        </div>
        <div v-else-if="tab==2" class="pt-5">
            <Notificaciones />
        </div>
        <div v-else-if="tab==3" class="pt-5">
            <Usuarios />
        </div>
        <div v-else-if="tab==4" class="pt-5">
            <Copys />
        </div>
        <div v-else-if="tab==5" class="pt-5">
            <Felizometro />
        </div>
        <div v-else-if="tab==6" class="pt-5">
            <RespuestasRapidas />
        </div>
        <div v-else-if="tab==7" class="pt-5">
            <WASitio />
        </div>
        <div v-else-if="tab==9" class="pt-5">
            <ConfiFilas />
        </div>
        <div v-else class="pt-5">
            <ComentariosPlat />
        </div>
        <Sidebar :visible="panelLateral" position="right" class="lateralParametros">
            <h4><b>{{seleccionado.nombre}}</b></h4>
            <div class="contenidoAsesorParametros mt-4">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text"># de px máximos</span>
                    </div>
                    <input v-model="seleccionado.num_pacientes" type="number" class="form-control">
                </div>
                <p class="h6 mt-3">Orden de los pacientes que recibirá:</p>
                <select v-model="seleccionado.cola" class="custom-select custom-select-sm">
                    <option value="ASC">Antiguos</option>
                    <option value="DESC">Nuevos</option>
                </select>
            </div>
            <button class="btn btn-secondary btn-sm float-left mt-2" @click="panelLateral=false">Cerrar</button>
            <button class="btn btn-success btn-sm float-right mt-2" @click="guardar(seleccionado.id, seleccionado.sesion, seleccionado.facebook, seleccionado.num_pacientes, seleccionado.cola)">Guardar <font-awesome-icon icon="save"/></button>
        </Sidebar>
    </div>
    </template>
    <style>
    .texto-verde {
      font-size:14px;
      font-weight:bold;
      color:#4d9702;
    }
    .parpadea {
      
      animation-name: parpadeo;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    
      -webkit-animation-name:parpadeo;
      -webkit-animation-duration: 1s;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
    }
    
    @-moz-keyframes parpadeo{  
      0% { opacity: 1.0; }
      50% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
    
    @-webkit-keyframes parpadeo {  
      0% { opacity: 1.0; }
      50% { opacity: 0.0; }
       100% { opacity: 1.0; }
    }
    
    @keyframes parpadeo {  
      0% { opacity: 1.0; }
       50% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
    </style>
    <script>
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave, faComments,faSmile,faHandHolding } from '@fortawesome/free-solid-svg-icons'
    library.add(faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave, faComments,faSmile,faHandHolding)
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import Sidebar from 'primevue/sidebar'
    import Notificaciones from './Notificaciones'
    import Usuarios from './Usuarios'
    import ConfiAsesor from './ConfiAsesor'
    import Copys from './Copys'
    import Felizometro from './Felizometro'
    import RespuestasRapidas from './RespuestasRapidas.vue'
    import WASitio from './WASitio.vue'
    import ComentariosPlat from './ComentariosPlat.vue'
    import ConfiFilas from './Fila.vue'
    
    export default {
        name: 'Parametros',
        data() {
            return {
                asesores: null,
                esperar: false,
                switch: false,
                checked:false,
                panelLateral:false,
                cola:null,
                nicaragua:{},
                tab:1,
                sesion: JSON.parse(sessionStorage.getItem('sesion')),
                seleccionado:{ "id": 0, "nombre": "Aqui aparece el nombre del paciente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" },
            }
        },
        components:{
        FontAwesomeIcon,
        Sidebar,
        Notificaciones,
        Usuarios,
        ConfiAsesor,
        Copys,
        Felizometro,
        RespuestasRapidas,
        WASitio,
        ComentariosPlat,
        ConfiFilas
    },
    created(){
        if(this.sesion.grupo_id==6){
            this.tab=3
        }
    
          if(this.sesion.grupo_id==10){
            this.tab=3
        }
    },
        // created(){
        //     let data = {
        //         query: {
        //             "Tipo": "3"
        //         }
        //     }
        //     let dataCola = {
        //         query: {
        //             "Tipo": "1"
        //         }
        //     }
    
        //     var socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
        //     socket.on('parametros',  (datos) => {
        //         var moment = require("moment")
        //         moment.locale("es")
        //         datos.map(x =>{
        //             x.lastconnection = moment(x.lastconnection)
        //             x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
        //             if((x.lastconnection.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
        //                 x.online=true
        //             }else{
        //                 x.online=false
        //             }
        //             if(this.sesion.rol_id==1){
        //                 return x
    
        //             } else{
        //                 return x.rol_id==3
        //             }
        //         })
        //         datos.sort((a,b) =>{
        //             return b.id - a.id
        //         })
        //         datos.sort((a,b) =>{
        //         return (a.online === b.online)? 0 : a.online? -1 : 1
        //       })
        //         this.asesores = datos
        //     })
        //     var socketCola = SocketIo.connect('https://socket-ws.salud-digna.site', dataCola)
        //     socketCola.on('pedirColaRedes',  (payload) => {
        //         this.cola=payload
        //     })
        //     let colaNicaragua = {
        //         query: {
        //             "Tipo": "2"
        //         }
        //     }
        //     var socketNicaragua = SocketIo.connect('https://socket-ws.salud-digna.site', colaNicaragua)
        //     socketNicaragua.on('pedirColaNicaragua',  (payload) => {
        //         this.nicaragua=payload
        //     })
            
        // },
        // mounted(){
        //     this.get()
        // },
        // methods: {
        //     get(){
        //         // reportesServices.asesorSesion().then(datos => {
        //         //     var moment = require("moment")
        //         //     moment.locale("es")
        //         //     datos.map(x =>{
        //         //         x.lastconnection = moment(x.lastconnection)
        //         //         x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
        //         //         if((x.lastconnection.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
        //         //             x.online=true
        //         //         }else{
        //         //             x.online=false
        //         //         }
        //         //         return x
        //         //     })
        //         //     this.asesores = datos
        //         // })
        //         controlesServices.Control().then(resp => {
        //             resp.map(x => {
        //                 if(x.id==1){
        //                     this.switch=x
        //                 } else{
        //                     return
        //                 }
        //                 return x
        //             })
                    
        //             if(this.switch.status==0){
        //                 this.checked=false
        //             }else{
        //                 this.checked=true
        //             }
        //         })
        //     },
        //     cambiarEstatus(id, estatus){
        //         this.esperar=true
        //         asesorServices.estatus(id, estatus).then(() =>{
        //             this.get()
        //             this.esperar=false
        //         })
        //     },
        //     mandarCola(a){
        //         this.esperar=true
        //         asesorServices.traspasarPacientesParametros(a).then(()=>{
        //                 this.get()
        //                 this.esperar=false
        //             })
        //     },
        //     cambiarSwitch(){
        //         let variable
        //         variable = this.checked ? 0:1
        //         controlesServices.CambiarControl(1, variable).then(() => {
        //             this.get()
        //         })
        //     },
        //     editar(asesor){
        //         this.seleccionado=asesor
        //         this.panelLateral=!this.panelLateral
        //     },
        //     guardar(a,b,c,d,e){
        //         this.esperar=true
                
        //         controlesServices.updateAsesorConfig(a,b,c,d,e).then(res=>{
        //             if(res){
        //                 this.panelLateral=false
        //                 this.seleccionado={ "id": 0, "nombre": "Aqui aparece el nombre del paciente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" }
        //             }
        //             this.esperar=false
        //         })
        //     }
        // },
    }
    </script>
    